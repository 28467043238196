import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import { requestApi } from 'api/request-api';
import { resourceApi } from 'api/resource-api';
import AddEditResource from 'components/shared/AddEditResource';
import { toFormValues, toApiValues } from 'components/shared/resource/api-form-mapper';
import { useAppContext } from 'contexts/app-context';
import useMounted from 'hooks/use-mounted';
import AddEditResourcePageForm from './AddEditResourcePageForm';
import { sectionChoices, myAccountSectionChoices } from './constants';

const apiEndpoint = 'resource-pages';
const resourceName = 'resource page';

const visibilityChoices = [
  {
    value: 'public',
    label: 'Public: visible to everyone',
  },
  {
    value: 'private',
    label: 'Private: only visible to site admins',
  },
];

const fields = [
  {
    name: 'name',
    label: 'Name'
  },
  {
    name: 'slug',
    label: 'Slug'
  },
  {
    name: 'parentResourcePage',
    label: 'Parent',
    type: 'select',
  },
  {
    name: 'section',
    label: 'Website section',
    type: 'select',
    choices: sectionChoices,
  },
  {
    name: 'myAccountSection',
    label: 'My Account section',
    type: 'select',
    choices: myAccountSectionChoices,
  },
  {
    name: 'tileImageUrl',
    label: 'Tile image',
    type: 'imagePicker',
  },
  {
    name: 'metaDataSocial',
    label: 'Meta data / Social',
    type: 'fieldGroup',
    asAccordion: true,
    childFields: [
      {
        name: 'metaTitle',
        label: 'Meta title',
      },
      {
        name: 'metaDescription',
        label: 'Meta description',
      },
      {
        name: 'excludeFromIndex',
        label: 'Exclude from search engines',
        type: 'checkbox',
      },
      {
        name: 'facebookImageUrl',
        label: 'Facebook image',
      },
      {
        name: 'facebookTitle',
        label: 'Facebook title',
      },
      {
        name: 'facebookDescription',
        label: 'Facebook description',
      },
      {
        name: 'twitterImageUrl',
        label: 'Twitter image',
      },
      {
        name: 'twitterTitle',
        label: 'Twitter title',
      },
      {
        name: 'twitterDescription',
        label: 'Twitter description',
      },
    ]
  },
  {
    name: 'visibility',
    label: 'Visibility',
    type: 'select',
    choices: visibilityChoices,
  },
  {
    name: 'displayOrder',
    label: 'Display order',
    type: 'number',
  },
  {
    name: 'isArchived',
    label: 'Archived',
    type: 'checkbox',
  },
  {
    name: 'departments',
    label: 'Page departments',
    type: 'selectAsTable',
    choices: [],
    defaultValue: [],
  },
  {
    name: 'zipIncludesPublicResources',
    label: 'Resource ZIP Archive Should Include Publicly Accessible Resources',
    type: 'checkbox',
  },
];

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  slug: Yup.string()
    .required('Slug is required'),
});

const AddEditResourcePage = () => {
  const [initialised, setInitialised] = useState(false);
  const [resourceMediaTypes, setResourceMediaTypes] = useState([]);

  const { setShowLoadingSpinner } = useAppContext();
  const isMounted = useMounted();
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  const initialise = useCallback(async () => {
    if (!isMounted()) {
      return;
    }

    setShowLoadingSpinner(true);
    const departmentChoices = await requestApi.getResponse({ url: 'departments/choices' });
    const departmentsField = fields.find((field) => field.name === 'departments');
    departmentsField.choices = departmentChoices;
    const resourcePageChoices = await requestApi.getResponse({ url: 'resource-pages/choices' });
    const noParentChoice = { value: 'none', label: 'No parent' }
    const parentResourcePageField = fields.find((field) => field.name === 'parentResourcePage');
    parentResourcePageField.choices = [noParentChoice, ...resourcePageChoices];
    const resourceMediaTypesResponse = await resourceApi.getResources({
      apiEndpoint: 'resource-media-types',
      sortValues: [{ field: 'displayOrder', direction: 'asc' }],
      pagination: { itemsPerPage: 999 },
    });
    setResourceMediaTypes(resourceMediaTypesResponse.resources);
    setInitialised(true);
    setShowLoadingSpinner(false);
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  // eslint-disable-next-line no-shadow
  const toFormValuesCustom = (resource, fields) => {
    // Perform standard mapping
    const formValues = toFormValues(resource, fields);

    // visibility is an array in the data, map it to a scalar, default public
    formValues.visibility = resource.visibility[0] ?? 'public';

    // Additional mapping for pageBlocks
    formValues.pageBlocks = [];
    resource.pageBlocks?.forEach(pageBlock => {
      const pageBlockFormValues = {};
      // Set @id, pageBlockType and identifier form values for the PageBlock
      pageBlockFormValues['@id'] = pageBlock['@id'];
      pageBlockFormValues.pageBlockType = pageBlock.pageBlockType['@id'];
      pageBlockFormValues.identifier = pageBlock.identifier;
      // Set the content form values for the PageBlock
      // If content is empty, it arrives here as an empty array - we need to cast this into an object
      if (Object.prototype.toString.call(pageBlock.content) === '[object Array]') {
        pageBlockFormValues.content = {};
      } else {
        pageBlockFormValues.content = pageBlock.content;
      }
      formValues.pageBlocks.push(pageBlockFormValues);
    })

    // Additional mapping for resources.
    // Each Resource has any number of ResourceMedias. The API returns only ResourceMedias that currently
    // exist (in any order), but the form renders a ResourceMedia field for each ResourceMediaType. This mapper
    // iterates the ResourceMediaTypes and EITHER finds a matching ResourceMedia OR creates one.
    formValues.resources = [];
    // eslint-disable-next-line no-shadow
    resource.resources?.forEach((resource) => {
      const resourceFormValues = {};
      // Set @id and name form values for the Resource
      resourceFormValues['@id'] = resource['@id'];
      resourceFormValues.name = resource.name;
      resourceFormValues.isPublic = resource.isPublic;
      resourceFormValues.isGated = resource.isGated;
      // Map resourceMedias into form values
      resourceFormValues.resourceMedias = [];
      resourceMediaTypes.forEach(resourceMediaType => {
        const resourceMediaForResourceMediaTypeIndex = resource.resourceMedias?.findIndex(
          resourceMedia => resourceMedia.resourceMediaType['@id'] === resourceMediaType['@id']
        );
        if (resourceMediaForResourceMediaTypeIndex >= 0) {
          resourceFormValues.resourceMedias.push(resource.resourceMedias[resourceMediaForResourceMediaTypeIndex]);
        } else {
          const resourceMedia = {};
          resourceMedia.resourceMediaType = resourceMediaType;
          resourceMedia.externalUri = '';
          resourceFormValues.resourceMedias.push(resourceMedia);
        }
      });
      formValues.resources.push(resourceFormValues);
    })

    return formValues;
  }

  // eslint-disable-next-line no-shadow
  const toApiValuesCustom = (formValues, fields) => {
    // Perform standard mapping
    const apiValues = toApiValues(formValues, fields);

    // visibility is a scalar in the data, map it to an array, default public
    apiValues.visibility = formValues.visibility ? [formValues.visibility] : ['public'];

    // Additional mapping for pageBlocks
    apiValues.pageBlocks = [];
    formValues.pageBlocks.forEach(pageBlock => {
      const pageBlockApiValues = {};
      // Set @id, pageBlockType and identifier API values for the PageBlock
      pageBlockApiValues['@id'] = pageBlock['@id'];
      pageBlockApiValues.pageBlockType = pageBlock.pageBlockType;
      pageBlockApiValues.identifier = pageBlock.identifier;
      // Set the content API values for the PageBlock
      pageBlockApiValues.content = pageBlock.content;
      apiValues.pageBlocks.push(pageBlockApiValues);
    });

    // Additional mapping for Resources - see notes in toFormValuesCustom.
    apiValues.resources = [];
    formValues.resources.forEach(resource => {
      const resourceApiValues = {};
      // Set @id and name API values for the Resource
      resourceApiValues['@id'] = resource['@id'];
      resourceApiValues.name = resource.name;
      resourceApiValues.isPublic = resource.isPublic;
      resourceApiValues.isGated = resource.isGated;
      // Map resourceMedias into API values
      resourceApiValues.resourceMedias = [];
      resource.resourceMedias.forEach(resourceMedia => {
        const resourceMediaApiValues = {};
        resourceMediaApiValues['@id'] = resourceMedia['@id'];
        resourceMediaApiValues.resourceMediaType = resourceMedia.resourceMediaType['@id'];
        resourceMediaApiValues.externalUri = resourceMedia.externalUri;
        // Skip any empty ResourceMedias
        if (resourceMediaApiValues.externalUri) {
          resourceApiValues.resourceMedias.push(resourceMediaApiValues);
        }
      });
      apiValues.resources.push(resourceApiValues);
    });

    return apiValues;
  };

  return (
    <AddEditResource
      addEditForm={AddEditResourcePageForm}
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      toApiValuesCustom={toApiValuesCustom}
      toFormValuesCustom={toFormValuesCustom}
      validationSchema={validationSchema}
    />
  );
};

export default AddEditResourcePage;
